import isMobile from './is-mobile.js';

//グローバルナビ メガメニュー
$('.js-megamenu .megamenu-wrapper').on({
  'touchstart mouseenter': function touchstartMouseenter() {
    if(!isMobile(768)){
      $(this).addClass('is-megaMenu--show');
      $("body").addClass('is-megaMenu--showPage');
      $(this).next().find(".megamenu-content").css({"z-index": 500});
      $(this).prev().find(".megamenu-content").css({"z-index": 500});
    }
  },
  'touchend mouseleave': function touchendMouseleave() {
    if(!isMobile(768)){
      $(this).removeClass('is-megaMenu--show');
      $("body").removeClass('is-megaMenu--showPage');
      $(this).next().find(".megamenu-content").attr({"style": ""});
      $(this).prev().find(".megamenu-content").attr({"style": ""});
    }
  }
});
//グローバルナビ メガメニュー スマホで親をタップしてもリンクしない
$('.js-megamenu .megamenu-wrapper').on("click", function(e){
  if(isMobile(768)){
    if($(e.target).closest("a").is(".megamenu-main-link")) {
      $(this).toggleClass('is-megaMenu--show');
    }
    return ! $(e.target).closest("a").is(".megamenu-main-link")
  }
})

import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();
var tl1 = new TimelineMax();

//------------------
//hero
//------------------
var scene_hero_detail = new ScrollMagic.Scene({
  triggerElement: ".top-hero-detail" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :300
})
// .addIndicators()
.addTo(controller);

scene_hero_detail.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-hero-detail').addClass('is-enter');
});
scene_hero_detail.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-hero-detail').removeClass('is-enter');
});

var scene_hero_text = new ScrollMagic.Scene({
  triggerElement: ".top-hero-detail__inner" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :550
})
// .addIndicators()
// .addTo(controller);
//
// scene_hero_text.on('enter', ()=>{
// //triggerElementを過ぎたとき
// var tween1 = tl1
// .to(".top-hero-detail__inner", 0.2, { autoAlpha: 1, delay: 0.3 })
// });
// scene_hero_text.on('leave', ()=>{
// //triggerElementを抜けたとき
//
// });



//------------------
//COLLABORATION
//------------------
var scene_content_enter = new ScrollMagic.Scene({
  triggerElement: ".top-content" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :50
})
// .addIndicators()
.addTo(controller);

scene_content_enter.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-enter');
});
scene_content_enter.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-enter');
});


var scene_content_text = new ScrollMagic.Scene({
  triggerElement: ".top-content" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :150
})
// .addIndicators()
.addTo(controller);

scene_content_text.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-content .l-title').addClass('is-show');
$('.top-page-nav').addClass('is-content-textChange');
});
scene_content_text.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-content-textChange');
});



var scene_content_show = new ScrollMagic.Scene({
  triggerElement: ".top-content" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :300
})
// .addIndicators()
.addTo(controller);

scene_content_show.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-show');
});
scene_content_show.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-show');
});


var scene_content_detail = new ScrollMagic.Scene({
  triggerElement: ".top-content-detail" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :150
})
// .addIndicators()
.addTo(controller);

scene_content_detail.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-content').addClass('is-show');
var tween1 = tl1
.to(".top-content-detail", 0.8, { autoAlpha: 1, delay: 1.2 })
});
scene_content_detail.on('leave', ()=>{
//triggerElementを抜けたとき
// $('.top-page-nav').removeClass('is-enter');
});


//------------------
//company
//------------------
var scene_company_enter = new ScrollMagic.Scene({
  triggerElement: ".top-company" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :-200
})
// .addIndicators()
.addTo(controller);

scene_company_enter.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-enter');
$('.top-page-nav').removeClass('is-show');
});
scene_company_enter.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-enter');
});


var scene_company_text = new ScrollMagic.Scene({
  triggerElement: ".top-company" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :0
})
// .addIndicators()
.addTo(controller);

scene_company_text.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-company-textChange');
});
scene_company_text.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-company-textChange');
});



var scene_company_show = new ScrollMagic.Scene({
  triggerElement: ".top-company" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :200
})
// .addIndicators()
.addTo(controller);

scene_company_show.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-show');
$('.top-company .l-title').addClass('is-show');
});
scene_company_show.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-show');
});




//------------------
//contact
//------------------
var scene_contact_enter = new ScrollMagic.Scene({
  triggerElement: ".top-contact" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :-200
})
// .addIndicators()
.addTo(controller);

scene_contact_enter.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-enter');
$('.top-page-nav').removeClass('is-show');
});
scene_contact_enter.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-enter');
});


var scene_contact_text = new ScrollMagic.Scene({
  triggerElement: ".top-contact" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :0
})
// .addIndicators()
.addTo(controller);

scene_contact_text.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-contact .l-title').addClass('is-show');
$('.top-page-nav').addClass('is-contact-textChange');
});
scene_contact_text.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-contact-textChange');
});



var scene_contact_show = new ScrollMagic.Scene({
  triggerElement: ".top-contact" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :200
})
// .addIndicators()
.addTo(controller);

scene_contact_show.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-show');
});
scene_contact_show.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-show');
});


//------------------
//footer
//------------------
var scene_footer_enter = new ScrollMagic.Scene({
  triggerElement: "#l-footer" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :20
})
// .addIndicators()
.addTo(controller);

scene_footer_enter.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.top-page-nav').addClass('is-hide');
});
scene_footer_enter.on('leave', ()=>{
//triggerElementを抜けたとき
$('.top-page-nav').removeClass('is-hide');
});



//------------------
//pagetop
//------------------
var scene_pagetop_show = new ScrollMagic.Scene({
  triggerElement: ".top-company" ,
  triggerHook: 'onEnter',
  // duration: 800,
  offset :0
})
// .addIndicators()
.addTo(controller);

scene_pagetop_show.on('enter', ()=>{
//triggerElementを過ぎたとき
$('.l-footer-pageTop').addClass('is-show');
});
scene_pagetop_show.on('leave', ()=>{
//triggerElementを抜けたとき
$('.l-footer-pageTop').removeClass('is-show');
});

// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/sp-menu.js';//スマホメニュー
import './modules/common/megamenu.js';//メガメニュー
import './modules/common/datepicker.js';
import './modules/common/tablefix.js';
import './modules/common/highlight.js';
import './modules/top/animation.js';
import './modules/top/video.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});





function imageGuard(selector){

  var guard_selector = document.querySelectorAll(selector);

  for(var n = 0; n < guard_selector.length; n++){
    guard_selector[n].addEventListener("contextmenu", function(e){
      e.preventDefault();
    }, false);
  }

  var guard_style = {
    'pointer-events':'none',
    '-webkit-touch-callout':'none',
    '-moz-touch-callout':'none',
    'touch-callout':'none',
    '-webkit-user-select':'none',
    '-moz-user-select':'none',
    'user-select':'none'
  }

  Object.keys(guard_style).forEach(function(v, i, a){
    for(var n = 0; n < guard_selector.length; n++){
      guard_selector[n].style[v] = guard_style[v];
    }
  });

}


// document.addEventListener("DOMContentLoaded", function() {
//   imageGuard('img');
// });
//
// $('.l-header__logo').append('<img src="/assets/img/common/logo.png">');

// const hoge = val => val + 1;
// console.log(hoge(1));

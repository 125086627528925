import isMobile from '../common/is-mobile.js';


if(isMobile(414)) {
  $('#main-video').attr('src', $('#main-video').attr('src').replace('md2', 'g20'));
}
 
if(!isMobile(767)) {
  $(window).on('resize', function(){
    var w = $(window).width();
    var h = $(window).height();

    var r_w = h * 16 / 9;
    var r_h = $(window).width() * 9/16;

    var mr = (r_w - w) /2;
    var videoHeight = $('#main-video').height();
    var videoWidth = $('#main-video').width();
    // console.log($('#main-video').width());

    if(h > r_h) {
      $('#main-video').css({
        'width': r_w + "px",
        'height': h + "px",
        'margin-left': -mr + "px"
      });
    } else {
      $('#main-video').css({
        'width': 100 + "%",
        'height': "auto"
      });
    }
  }).trigger('resize');
}
